import {IConfiguration, IFeatureFlags, IGetConfigurations, INetsuiteAuthentication,} from "models";
import React, {ReactNode} from "react";
import {ConfigurationService} from "services";
import {FeatureFlags} from "enums";
import {getConfigurationFromLocalStorage, getCustomFieldsMap} from "utils";
import {INetsuiteSyncTest} from "models/NetsuiteSyncTest";

export interface ConfigurationProviderProps {
    children: ReactNode;
    token: string;
}

const isInsideModal = window.location !== window.parent.location;
const defaultLocale = 'en';
const defaultDesignToken = {};
const defaultWarningsSyncConfiguration: string[] = [];

const initEmbarkData = {
    isDarkMode: false,
    isInsideEmbark: isInsideModal,
    locale: defaultLocale,
    designToken: defaultDesignToken,
    designTokenDarkMode: defaultDesignToken
};

const initConfigurationData = {
    configurationId: '',
    configuration: {} as IConfiguration,
    setConfiguration: (config: IConfiguration) => {
    },
    initialConfiguration: {} as IConfiguration,
    setInitialConfiguration: (config: IConfiguration) => {
    },
    updatedConfiguration: {} as IConfiguration,
    setUpdatedConfiguration: (config: IConfiguration) => {
    },
    hasConfigurationError: false,
    netSuiteCustomEmployeeFields: {} as Map<string, string>,
    netSuiteCustomVendorFields: {} as Map<string, string>,
    netSuiteCustomExpenseFields: {} as Map<string, string>,
    setNetSuiteCustomEmployeeFields: (customFields: Map<string, string>) => {
    },
    setNetSuiteCustomVendorFields: (customFields: Map<string, string>) => {
    },
    setNetSuiteCustomExpenseFields: (customFields: Map<string, string>) => {
    },
    countriesList: {} as Map<string, string>
};

const initTestAuthenticationData = {
    netsuiteAuthentication: {} as INetsuiteAuthentication,
    setNetsuiteAuthentication: (netsuiteAuthentication: INetsuiteAuthentication) => {
    },
    savedNetSuiteAuthenticateStatus: '',
    setSavedNetSuiteAuthenticateStatus: (savedNetSuiteAuthenticateStatus: string) => {
    },
    isAuthenticationStatusSuccess: false,
    setIsAuthenticationStatusSuccess: (isAuthenticationStatusSuccess: boolean) => {
    }
};

const initTestSyncData = {
    displayTimeoutMessage: false,
    setDisplayTimeoutMessage: (setDisplayTimeoutMessage: boolean) => {
    },
    syncTest: {} as INetsuiteSyncTest,
    setSyncTest: (netsuiteSyncTest: INetsuiteSyncTest) => {
    },
    successfulSyncConfiguration: false,
    setSuccessfulSyncConfiguration: (setSuccessfulSyncConfiguration: boolean) => {
    },
    successfulValidConfiguration: false,
    setSuccessfulValidConfiguration: (setSuccessfulValidConfiguration: boolean) => {
    },
    errorSyncConfiguration: '',
    setErrorSyncConfiguration: (setErrorSyncConfiguration: string) => {
    },
    warningsSyncConfiguration: defaultWarningsSyncConfiguration
};

const initFeatureFlagsData = {
    featureFlags: {
        customFieldsFeatureEnabled: false,
        expenseReportCorporateCardAccountFeatureEnabled: false,
        exportExpenseReceiptsFeatureEnabled: false,
        netsuiteTaxesFeatureEnabled: false
    }
};

export const initData = {
    companyId: '',
    token: '',
    finishLoading: false,
    hasError: false,
    setHasError: (hasError: boolean) => {
    },
    ...initConfigurationData,
    ...initTestAuthenticationData,
    ...initTestSyncData,
    ...initEmbarkData,
    ...initFeatureFlagsData
};

/* The context itself */
export const ConfigurationContext = React.createContext(initData);

export const ConfigurationProvider = (props: ConfigurationProviderProps) => {
    const [configuration, setGlobalConfiguration] = React.useState<IConfiguration>(initData.configuration);
    const [hasConfigurationError, setHasConfigurationError] = React.useState<boolean>(false);
    const [finishLoading, setFinishLoading] = React.useState<boolean>(false);
    const [hasError, setHasError] = React.useState<boolean>(false);
    const [configurationId, setConfigurationId] = React.useState<string>('');
    const [companyId, setCompanyId] = React.useState<string>('');
    const token = props.token;
    const [netSuiteCustomEmployeeFields, setNetSuiteCustomEmployeeFields] = React.useState<Map<string, string>>(new Map());
    const [netSuiteCustomVendorFields, setNetSuiteCustomVendorFields] = React.useState<Map<string, string>>(new Map());
    const [netSuiteCustomExpenseFields, setNetSuiteCustomExpenseFields] = React.useState<Map<string, string>>(new Map());
    const [countriesList, setCountriesList] = React.useState<Map<string, string>>(new Map());
    const [loadedPages, setLoadedPages] = React.useState<boolean>(true);
    const setConfiguration = (config: IConfiguration) => setGlobalConfiguration({...configuration, ...config});
    const configurationService = new ConfigurationService(token);
    const [isDarkMode, setIsDarkMode] = React.useState<boolean>(false);
    const [isInsideEmbark, setIsInsideEmbark] = React.useState<boolean>(isInsideModal);
    const [locale, setLocale] = React.useState<string>(defaultLocale);
    const [designToken, setDesignToken] = React.useState(defaultDesignToken);
    const [designTokenDarkMode, setDesignTokenDarkMode] = React.useState(defaultDesignToken);
    const [savedNetSuiteAuthenticateStatus, setSavedNetSuiteAuthenticateStatus] = React.useState<string>('');
    const [isAuthenticationStatusSuccess, setIsAuthenticationStatusSuccess] = React.useState<boolean>(false);
    const [netsuiteAuthentication, setNetsuiteAuthentication] = React.useState<INetsuiteAuthentication>({inProgress: false});
    const [syncTest, setSyncTest] = React.useState<INetsuiteSyncTest>({inProgress: false});
    const [displayTimeoutMessage, setDisplayTimeoutMessage] = React.useState<boolean>(false);
    const [successfulSyncConfiguration, setSuccessfulSyncConfiguration] = React.useState<boolean>(false);
    const [successfulValidConfiguration, setSuccessfulValidConfiguration] = React.useState<boolean>(false);
    const [initialConfiguration, setInitialConfiguration] = React.useState<IConfiguration>(initData.configuration);
    const [updatedConfiguration, setUpdatedConfiguration] = React.useState<IConfiguration>(initData.configuration);
    const [errorSyncConfiguration, setErrorSyncConfiguration] = React.useState<string>('');
    const [warningsSyncConfiguration, setWarningsSyncConfiguration] = React.useState<string[]>([]);
    const featureFlags: IFeatureFlags = initData.featureFlags;

    const getFeatureFlags = async(configurationService: ConfigurationService) => {
        featureFlags.customFieldsFeatureEnabled = await configurationService.getFeatureFlag(FeatureFlags.CUSTOM_FIELDS_FEATURE_ENABLED);
        featureFlags.expenseReportCorporateCardAccountFeatureEnabled = await configurationService.getFeatureFlag(FeatureFlags.EXPENSE_REPORT_CORPORATE_CARD_ACCOUNT_FEATURE_ENABLED);
        featureFlags.exportExpenseReceiptsFeatureEnabled = await configurationService.getFeatureFlag(FeatureFlags.EXPORT_EXPENSE_RECEIPTS_FEATURE_ENABLED);
        featureFlags.netsuiteTaxesFeatureEnabled = await configurationService.getFeatureFlag(FeatureFlags.NETSUITE_TAXES_FEATURE_ENABLED);
    }

    const populateCustomFields = async (response: IGetConfigurations)=> {
        await configurationService.getEmployeeCustomFields(response.id).then(response => {
            setNetSuiteCustomEmployeeFields(getCustomFieldsMap(response));
        });
        await configurationService.getVendorCustomFields(response.id).then(response => {
            setNetSuiteCustomVendorFields(getCustomFieldsMap(response));
        });
        await configurationService.getExpenseCustomFields(response.id).then(response => {
            setNetSuiteCustomExpenseFields(getCustomFieldsMap(response));
        });
    }

    const populateCountries = async() => {
        await configurationService.getCountriesList().then(response => {
            setCountriesList(response);
        });
    }

    const getConfigurationData = async() => {
        await configurationService.getConfigurations().then(response => {
                if(response !== null) {
                    setCompanyId(response.companyId);
                    setConfigurationId(response.id)
                    setSavedNetSuiteAuthenticateStatus(response.credential?.authenticateStatus as string);
                    setHasConfigurationError(false);
                    setSuccessfulSyncConfiguration(response.status === 'ACTIVE');
                    setSuccessfulValidConfiguration(response.status === 'ACTIVE');

                    const initConfiguration = getConfigurationFromLocalStorage(response.companyId, response.id);
                    setInitialConfiguration(initConfiguration);
                    setUpdatedConfiguration(initConfiguration);

                    getFeatureFlags(configurationService).then(() => {
                        if(featureFlags.netsuiteTaxesFeatureEnabled) {
                            populateCountries();
                        }

                        if(featureFlags.customFieldsFeatureEnabled) {
                            populateCustomFields(response);
                        }
                    });

                } else {
                    setHasConfigurationError(true);
                }
                setFinishLoading(true);
                setLoadedPages(false);
            }
        )
    };

    const handleIFrameModalMessage = (event: any) => {
        if(event?.data?.message === 'embarkIFrameModal') {
            setIsInsideEmbark(event.data.value.isEmbark);
            setIsDarkMode(event.data.value.isDarkMode);
            setLocale(event.data.value.locale);
            setDesignToken(event.data.value.designToken);
            setDesignTokenDarkMode(event.data.value.designTokenDarkMode);
        }
    };

    window.addEventListener('message', handleIFrameModalMessage);

    React.useEffect(() => {
        if(loadedPages) {
            getConfigurationData();
        }
        // Prep to know if inside Embark modal
        return () => {
            window.removeEventListener('message', handleIFrameModalMessage);
        };
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const contextProvider = {
        configuration,
        setConfiguration,
        initialConfiguration,
        setInitialConfiguration,
        updatedConfiguration,
        setUpdatedConfiguration,
        hasConfigurationError,
        netSuiteCustomEmployeeFields,
        netSuiteCustomVendorFields,
        netSuiteCustomExpenseFields,
        setNetSuiteCustomEmployeeFields,
        setNetSuiteCustomVendorFields,
        setNetSuiteCustomExpenseFields,
        countriesList,
        finishLoading,
        hasError,
        setHasError,
        configurationId,
        companyId,
        token,
        isDarkMode,
        isInsideEmbark,
        isAuthenticationStatusSuccess,
        setIsAuthenticationStatusSuccess,
        savedNetSuiteAuthenticateStatus,
        setSavedNetSuiteAuthenticateStatus,
        netsuiteAuthentication,
        setNetsuiteAuthentication,
        syncTest,
        setSyncTest,
        locale,
        designToken,
        designTokenDarkMode,
        displayTimeoutMessage,
        setDisplayTimeoutMessage,
        successfulSyncConfiguration,
        setSuccessfulSyncConfiguration,
        successfulValidConfiguration,
        setSuccessfulValidConfiguration,
        errorSyncConfiguration,
        setErrorSyncConfiguration,
        warningsSyncConfiguration,
        setWarningsSyncConfiguration,
        featureFlags
    };

    return (
        <ConfigurationContext.Provider value={contextProvider}>
            {props.children}
        </ConfigurationContext.Provider>
    );
};

 