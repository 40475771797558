export enum RouteLink {
    Home = '/',
    NetsuiteHome = '/netsuite',
    AccountCredentials = '/account-credentials',
    General = '/general',
    Users = '/users',
    CustomersAndProjects = '/customers-and-projects',
    Expenses = '/expenses',
    Review = '/review'
}
